<div class="grid grid-cols-2 gap-x-2">
  <ng-select [(ngModel)]="sBot" (ngModelChange)="sBotChanged($event)" [clearable]="true"
             class="w-full self-start ng-select cheqme-style block rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
    @for (bot of bots; track bot.id) {
      <ng-option [value]="bot">{{ bot.name }}</ng-option>
    })
  </ng-select>
  @if (sBot) {
    <div class="flex flex-col w-full gap-y-1">
      <ng-select [clearable]="false" [(ngModel)]="sChannel" (ngModelChange)="sChannelChanged($event)"
                 class="w-full self-start ng-select cheqme-style block rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
        @for (channel of sBot.linkedAuthUnits; track channel.id) {
          <ng-option [value]="channel">{{ channel.name }}</ng-option>
        })
      </ng-select>
      @if (_action != undefined) {
        @for (value of getSortedModels(); track value.id) {
          <div class="inline-flex w-full gap-x-2">
            <a class="themed-link h-4 w-4 place-self-center" (click)="_action.remove(value.id)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                <path fill="currentColor"
                      d="M208.49 191.51a12 12 0 0 1-17 17L128 145l-63.51 63.49a12 12 0 0 1-17-17L111 128L47.51 64.49a12 12 0 0 1 17-17L128 111l63.51-63.52a12 12 0 0 1 17 17L145 128Z"/>
              </svg>
            </a>
            <p>{{ value.name }}</p>
          </div>
        }
      }
    </div>
  }
</div>
