<div class="inline-flex w-full py-2">
  <h3 class="fw-bold text-xl">Filter ({{ workflow.filters.length }})</h3>
  <a class="themed-link ml-auto mt-auto"
     (click)="workflow.addFilter()">
    hinzufügen
  </a>
</div>
@if (workflow.filters.length == 0) {
  <p>Keine Filter vorhanden.</p>
} @else {
  @for (filter of workflow.filters; track filter.id) {
    <app-transaction-workflow-filter [model]="filter" [last]="$last"
                                     (onRemove)="removeFilter($event)"></app-transaction-workflow-filter>
  }
}
<div class="inline-flex w-full py-2">
  <h3 class="fw-bold text-xl">Aktionen</h3>
  <a class="themed-link ml-auto mt-auto"
     (click)="workflow.addAction()">
    hinzufügen
  </a>
</div>
@if (workflow.actions.length == 0) {
  <p>Keine Aktionen vorhanden.</p>
} @else {
  @for (action of workflow.actions; track action.id) {
    <app-transaction-workflow-action [model]="action" [last]="$last"
                                     (onRemove)="removeAction($event)"></app-transaction-workflow-action>
  }
}
<div class="inline-flex w-full">
  <div class="ml-auto inline-flex gap-x-4 mt-2">
    <a class="place-self-center themed-link" (click)="reset()">zurücksetzen</a>
    <button class="themed-button" (click)="save()">Speichern</button>
  </div>
</div>
@if (workflows.length > 0) {
  <div class="flex flex-col w-full space-y-3">
    <div class="inline-flex w-full py-2">
      <h3 class="fw-bold text-xl">Existierende Workflows</h3>
    </div>
    @for (w of workflows; track w.id) {
      <div class="flex flex-col w-full">
        <a class="inline-flex">
          <div class="inline-flex" (click)="collapseMap[w.id] = !collapseMap[w.id]">
            <svg class="h-6 w-6 place-self-center transition-all" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 256 256"
                 [class.rotate-90]="collapseMap[w.id]">
              <path fill="currentColor"
                    d="m181.66 133.66l-80 80A8 8 0 0 1 88 208V48a8 8 0 0 1 13.66-5.66l80 80a8 8 0 0 1 0 11.32"/>
            </svg>
            <h3 class="place-self-center">{{ w.name ?? w.id }}</h3>
          </div>
          <div class="inline-flex ml-auto space-x-4">
            <a *ngIf="PermissionService.CheckPermission(PermissionService.Automation_Workflow_Get())"
               [routerLink]="['/' + Routes.AutomationModule + '/' + AutomationRoutes.WorkflowsTransactionSettings]"
               [queryParams]="{'id': w.id}">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pen"
                   viewBox="0 0 16 16">
                <path
                  d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
              </svg>
            </a>
          </div>
        </a>
        <div class="grid grid-cols-7" *ngIf="collapseMap[w.id]">
          <ul class="w-full pl-6 my-2 col-span-3" role="list">
            @for (filter of w.filters; track filter.id; let last = $last; let first = $first) {
              <li>
                <div class="relative pb-4">
                  <span class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"
                        *ngIf="!last"></span>
                  <div class="relative flex space-x-3">
                    <div>
                      <span class="flex size-8 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white">
                        <svg class="size-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path
                          fill="currentColor"
                          d="M228 48v160a20 20 0 0 1-20 20h-68a12 12 0 0 1 0-24h64V52H52v88a12 12 0 0 1-24 0V48a20 20 0 0 1 20-20h160a20 20 0 0 1 20 20m-99.51 103.51a12 12 0 0 0-17 0L64 199l-15.51-15.49a12 12 0 1 0-17 17l24 24a12 12 0 0 0 17 0l56-56a12 12 0 0 0 0-17"/></svg>
                      </span>
                    </div>
                    <div class="w-full flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                      <div class="w-full">
                        <p class="text-sm border-b">
                          {{ first ? 'Wenn' : 'und wenn' }}
                          <b>{{ TransactionWorkflowFilterTargetsLabels[filter.target] }}</b>
                          {{ WorkflowFilterTypeLabels[filter.type] }}:
                        </p>
                        @switch (filter.target) {
                          @case (TransactionWorkflowFilterTarget.BookingType) {
                            <p
                              *ngFor="let value of filter.values">{{ BookingTypeLabels[parseInt(value.value)] }}</p>
                          }
                          @case (TransactionWorkflowFilterTarget.Composition) {
                            <p *ngFor="let value of filter.values">
                              <b *ngIf="compositions[value.value]?.active == false">[Gelöscht]</b>
                              {{ compositions[value.value]?.name }}
                            </p>
                          }
                          @case (TransactionWorkflowFilterTarget.CompositionType) {
                            <p *ngFor="let value of filter.values">
                              <b *ngIf="compositionTypes[value.value]?.active == false">[Gelöscht]</b>
                              {{ compositionTypes[value.value]?.name }}
                            </p>
                          }
                        }
                      </div>
                      <div class="whitespace-nowrap text-right text-sm text-gray-500">
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            }
          </ul>
          <div class="h-full w-full relative text-gray-500">
            <svg class="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 size-12"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
              <path fill="currentColor"
                    d="m237.66 122.34l-96-96A8 8 0 0 0 128 32v40H72a8 8 0 0 0-8 8v96a8 8 0 0 0 8 8h56v40a8 8 0 0 0 13.66 5.66l96-96a8 8 0 0 0 0-11.32M144 204.69V176a8 8 0 0 0-8-8H80V88h56a8 8 0 0 0 8-8V51.31L220.69 128ZM48 80v96a8 8 0 0 1-16 0V80a8 8 0 0 1 16 0"/>
            </svg>
          </div>
          <ul class="w-full pl-6 my-2 col-span-3" role="list">
            @for (action of w.actions; track action.id; let last = $last; let first = $first) {
              <li>
                <div class="relative pb-4">
                  <span class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"
                        *ngIf="!last"></span>
                  <div class="relative flex space-x-3">
                    <div>
                      <span class="flex size-8 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white">
                        <svg class="size-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path
                          fill="currentColor"
                          d="M236 192a12 12 0 0 1-12 12h-63.06a19.89 19.89 0 0 1-17.88-11.06L92.58 92H32a12 12 0 0 1 0-24h63.06a19.89 19.89 0 0 1 17.88 11.06L163.42 180H224a12 12 0 0 1 12 12M152 92h72a12 12 0 0 0 0-24h-72a12 12 0 0 0 0 24"/></svg>
                      </span>
                    </div>
                    <div class="w-full flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                      <div class="w-full">
                        <p class="text-sm border-b">
                          {{ first ? 'dann' : 'und' }}
                          <b>{{ WorkflowActionTargetLabels[action.target] }}</b>
                          <span>:</span>
                        </p>
                        @switch (action.target) {
                          @case (WorkflowActionTarget.Printer) {
                            <p *ngFor="let value of action.values">
                              <b *ngIf="printers[value.value]?.active == false">[Gelöscht]</b>
                              {{ printers[value.value]?.name }}
                            </p>
                          }
                          @case (WorkflowActionTarget.Telegram) {
                            <p *ngFor="let value of action.values">
                              <b *ngIf="telegram[value.value]?.active == false">[Gelöscht]</b>
                              {{ telegram[value.value]?.name }}
                            </p>
                          }
                        }
                      </div>
                      <div class="whitespace-nowrap text-right text-sm text-gray-500">
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            }
          </ul>
        </div>
      </div>
    }
  </div>
}
