import {Component, Input} from '@angular/core';
import {TelegramHttpService} from "../../../../../company/x-http-requests/telegram-http.service";
import {TransactionWorkflowAction} from "../../../../_models/WorkflowTargets/transaction-workflow-action";
import {WorkflowActionTarget} from "../../../../_models/_enums/workflow-action-target";
import {AuthUnit} from "../../../../../company/x-models/auth-unit";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {queue} from "../../../../../../helpers/delay-helper";

@Component({
  selector: 'app-telegram-selector',
  standalone: true,
  imports: [
    NgSelectModule,
    FormsModule
  ],
  templateUrl: './telegram-selector.component.html',
  styleUrl: './telegram-selector.component.scss'
})
export class TelegramSelectorComponent {
  constructor(private telegram: TelegramHttpService) {
  }

  protected _action: TransactionWorkflowAction | undefined;
  @Input() set action(action: TransactionWorkflowAction | undefined) {
    this._action = action;
    if (this._action !== undefined) this._action.values = [];
  }

  @Input() set type(type: WorkflowActionTarget) {
    this.bots = [];
    if (this._action !== undefined) this._action.values = [];
    if (type !== WorkflowActionTarget.Telegram) return;
    this.load();
  }

  protected bots: AuthUnit[] = [];

  private load() {
    this.telegram.ListBots().subscribe({
      next: bots => {
        this.bots = bots;
      }
    })
  }

  protected sBot: AuthUnit | null = null;

  sBotChanged(sBot: AuthUnit | null) {
    if (this._action != undefined) this._action.values = [];
  }

  protected sChannel: AuthUnit | null = null;

  sChannelChanged(sChannel: AuthUnit | null) {
    if (this.sChannel != null && this._action != undefined) {
      this._action.add(this.sChannel.id);
    }
    queue().then(() => this.sChannel = null);
  }

  protected findModel(id: string): AuthUnit | undefined {
    let channel = this.sBot?.linkedAuthUnits?.find(channel => channel.id === id);
    return channel;
  }

  protected getSortedModels(): AuthUnit[] {
    if (this._action === undefined) return [];
    return this._action.values
      .map(v => this.findModel(v.value))
      .filter(channel => channel !== undefined)
      .map(channel => channel as AuthUnit)
      .sort((a, b) => a.name.localeCompare(b.name));
  }
}
