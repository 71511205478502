import {Component, Input, OnInit} from '@angular/core';
import {TransactionWorkflow} from "../../_models/WorkflowTargets/transaction-workflow";
import {WorkflowTarget} from "../../_models/_enums/workflow-target";
import {TransactionWorkflowFilter} from "../../_models/WorkflowTargets/transaction-workflow-filter";
import {
  TransactionWorkflowFilterComponent
} from "./_components/transaction-workflow-filter/transaction-workflow-filter.component";
import {
  TransactionWorkflowActionComponent
} from "./_components/transaction-workflow-action/transaction-workflow-action.component";
import {TransactionWorkflowAction} from "../../_models/WorkflowTargets/transaction-workflow-action";
import {WorkflowHttpService} from "../../_services/workflow-http.service";
import {GlobalAlertService} from "../../../../_services/global-alert.service";
import {AlertLevel} from "../../../../enums/alert-level";
import {
  TransactionWorkflowFilterTarget,
  TransactionWorkflowFilterTargetsLabels
} from "../../_models/WorkflowTargets/transaction-workflow-filter-target";
import {WorkflowFilterTypeLabels} from "../../_models/_enums/workflow-filter-type";
import {NgForOf, NgIf} from "@angular/common";
import {BookingTypeLabels} from "../../../../models/payment/booking-type";
import {CompositionHttpService} from "../../../../http-requests/composition-http.service";
import {CompositionNl} from "../../../../models/composition-nl";
import {CompositionTypeNl} from "../../../../models/composition-type/composition-type-nl";
import {CompositionTypeHttpService} from "../../../../http-requests/composition-type-http.service";
import {WorkflowActionTarget, WorkflowActionTargetLabels} from "../../_models/_enums/workflow-action-target";
import {TelegramHttpService} from "../../../company/x-http-requests/telegram-http.service";
import {PrinterHttpService} from "../../../location/_services/printer-http.service";
import {AuthUnit} from "../../../company/x-models/auth-unit";
import {PrinterNl} from "../../../location/_models/printer-nl";
import {Routes} from "../../../../enums/routes";
import {AutomationRoutes} from "../../x-models/x-enums/automation-routes";
import {RouterLink} from "@angular/router";
import {PermissionService} from "../../../../_auth/permission.service";

@Component({
  selector: 'app-transaction-workflow',
  standalone: true,
  imports: [
    TransactionWorkflowFilterComponent,
    TransactionWorkflowActionComponent,
    NgForOf,
    NgIf,
    RouterLink
  ],
  templateUrl: './transaction-workflow.component.html',
  styleUrl: './transaction-workflow.component.scss'
})
export class TransactionWorkflowComponent implements OnInit {
  constructor(private workflowHttpService: WorkflowHttpService,
              private compositionHttpService: CompositionHttpService,
              private compositionTypeHttpService: CompositionTypeHttpService,
              private telegramHttpService: TelegramHttpService,
              private printerHttpService: PrinterHttpService,
              private gas: GlobalAlertService,
              protected PermissionService: PermissionService) {
  }

  ngOnInit(): void {
    this.load();
  }

  workflow: TransactionWorkflow = new TransactionWorkflow();

  @Input() set type(target: WorkflowTarget) {
    this.workflow = new TransactionWorkflow();
  }

  protected removeFilter(filter: TransactionWorkflowFilter) {
    this.workflow.filters = this.workflow.filters.filter(f => f !== filter);
  }

  protected removeAction(action: TransactionWorkflowAction) {
    this.workflow.actions = this.workflow.actions.filter(a => a !== action);
  }

  protected reset() {
    this.workflow = new TransactionWorkflow();
  }

  protected save() {
    if (!this.workflow.validate()) {
      this.gas.createAndShow("Fehler", "Workflow ist nicht vollständig.", AlertLevel.error);
      return;
    }
    this.workflowHttpService.add(this.workflow).subscribe({
      next: value => {
        this.gas.createAndShow("Erfolg", "Workflow erfolgreich gespeichert.", AlertLevel.success);
        this.workflow = new TransactionWorkflow();
        this.load();
      }, error: error => {
        this.gas.createAndShow("Fehler", "Workflow konnte nicht gespeichert werden.", AlertLevel.error);
        console.error(error);
      }
    });
  }

  protected workflows: TransactionWorkflow[] = [];
  protected compositions: { [key: string]: CompositionNl | undefined } = {};
  protected compositionTypes: { [key: string]: CompositionTypeNl | undefined } = {};
  protected telegram: { [key: string]: AuthUnit | undefined } = {};
  protected printers: { [key: string]: PrinterNl | undefined } = {};

  private load() {
    this.workflowHttpService.list().subscribe({
      next: workflows => {
        this.workflows = workflows;

        let compositions = this.workflows
          .map(x => x.filters).flat()
          .filter(x => x.target == TransactionWorkflowFilterTarget.Composition)
          .map(x => x.values).flat()
          .map(x => x.value);

        this.compositionHttpService.listByIds(compositions).subscribe({
          next: compositions => {
            let c: { [key: string]: CompositionNl } = {};
            this.compositions = compositions.reduce((acc, cur) => {
              acc[cur.id] = cur;
              return acc;
            }, c);
          },
          error: error => {
            this.gas.createAndShow("Fehler", "Artikel konnten nicht geladen werden.", AlertLevel.error);
            console.error(error);
          }
        });

        let compositionTypes = this.workflows
          .map(x => x.filters).flat()
          .filter(x => x.target == TransactionWorkflowFilterTarget.CompositionType)
          .map(x => x.values).flat()
          .map(x => x.value);

        this.compositionTypeHttpService.listByIds(compositionTypes).subscribe({
          next: compositionTypes => {
            let c: { [key: string]: CompositionTypeNl } = {};
            this.compositionTypes = compositionTypes.reduce((acc, cur) => {
              acc[cur.id] = cur;
              return acc;
            }, c);
          },
          error: error => {
            this.gas.createAndShow("Fehler", "Artikel-Kategorien konnten nicht geladen werden.", AlertLevel.error);
            console.error(error);
          }
        })

        let telegram = this.workflows
          .map(x => x.actions).flat()
          .filter(x => x.target == WorkflowActionTarget.Telegram)
          .map(x => x.values).flat()
          .map(x => x.value);

        this.telegramHttpService.listByIds(telegram).subscribe({
          next: telegram => {
            let t: { [key: string]: AuthUnit } = {};
            this.telegram = telegram.reduce((acc, cur) => {
              acc[cur.id] = cur;
              return acc;
            }, t);
          },
          error: error => {
            this.gas.createAndShow("Fehler", "Telegram-Channel konnten nicht geladen werden.", AlertLevel.error);
            console.error(error);
          }
        });

        let printers = this.workflows
          .map(x => x.actions).flat()
          .filter(x => x.target == WorkflowActionTarget.Printer)
          .map(x => x.values).flat()
          .map(x => x.value);

        this.printerHttpService.listByIds(printers).subscribe({
          next: printers => {
            let p: { [key: string]: PrinterNl } = {};
            this.printers = printers.reduce((acc, cur) => {
              acc[cur.id] = cur;
              return acc;
            }, p);
          },
          error: error => {
            this.gas.createAndShow("Fehler", "Drucker konnten nicht geladen werden.", AlertLevel.error);
            console.error(error);
          }
        });
      },
      error: error => {
        this.gas.createAndShow("Fehler", "Workflows konnten nicht geladen werden.", AlertLevel.error);
        console.error(error);
      }
    })
  }

  protected collapseMap: { [key: string]: boolean } = {};
  protected readonly TransactionWorkflowFilterTargetsLabels = TransactionWorkflowFilterTargetsLabels;
  protected readonly WorkflowFilterTypeLabels = WorkflowFilterTypeLabels;
  protected readonly TransactionWorkflowFilterTarget = TransactionWorkflowFilterTarget;
  protected readonly BookingTypeLabels = BookingTypeLabels;
  protected readonly parseInt = parseInt;
  protected readonly TransactionWorkflowAction = TransactionWorkflowAction;
  protected readonly WorkflowActionTargetLabels = WorkflowActionTargetLabels;
  protected readonly WorkflowActionTarget = WorkflowActionTarget;
  protected readonly Routes = Routes;
  protected readonly AutomationRoutes = AutomationRoutes;
}
