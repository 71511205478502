import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TelegramHttpService} from "../x-http-requests/telegram-http.service";
import {AuthUnit} from "../x-models/auth-unit";
import { HttpErrorResponse } from "@angular/common/http";
import {CompanyHttpService} from "../x-http-requests/company-http.service";
import {TelegramChannelConfiguration} from "../x-models/telegram-channel-configuration";
import copy from 'copy-to-clipboard';
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {AlertLevel} from "../../../enums/alert-level";
import {PermissionService} from "../../../_auth/permission.service";
import {HelpTypeLabel} from "../x-models/help-type";


@Component({
  selector: 'app-telegram',
  templateUrl: './telegram.component.html',
  styleUrls: ['./telegram.component.scss']
})
export class TelegramComponent implements OnInit {
  @ViewChild("botToken") botToken: ElementRef<HTMLInputElement> | undefined;
  @ViewChild("channel") channelId: ElementRef<HTMLInputElement> | undefined;

  bots: AuthUnit[] | undefined;
  selectedBot: AuthUnit | undefined;
  channelConfig: TelegramChannelConfiguration | undefined;
  error: HttpErrorResponse | undefined;

  constructor(private telegramHttpService: TelegramHttpService,
              private companyHttpService: CompanyHttpService,
              private globalAlertService: GlobalAlertService,
              public PermissionService: PermissionService) { }

  Copy(text: string) {
    copy(text, {
      debug: true,
      message: 'Press #{key} to copy',
    });
  }

  AddBot(): void {
    this.telegramHttpService.AddBot(this.botToken!.nativeElement!.value).subscribe(x => {
      if (this.bots == undefined) this.bots = [];
      this.bots.splice(0,0,x);
      this.globalAlertService.createAlertBannerModel("Erfolg", `Bot wurde erfolgreich hinzugefügt.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Es ist ein Fehler aufgetreten.", AlertLevel.error, 2000);
      this.globalAlertService.show();
      this.error = error;
      console.error(error);
    })
  }

  RemoveBot(): void {
    let confirmation = confirm("Folgenden Telegram-Bot sicher löschen:\n" + `${this.selectedBot!.id} ${this.selectedBot!.name}`);
    if (!confirmation) return;

    this.telegramHttpService.DeleteBot(this.selectedBot!.id).subscribe((x: any) => {
      this.bots!.splice(this.bots!.indexOf(this.selectedBot!), 1);
      this.globalAlertService.createAlertBannerModel("Löschen erfolgreich", `Das Löschen des Bots war erfolgreich.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Das Löschen des Bots ist fehlgeschlagen.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  AddChannel(): void {
    let channel = this.channelId!.nativeElement!.value;
    if (channel == "") return;

    if (channel.indexOf("#-") > -1 && channel.indexOf("https://") > -1) {
      channel = "-100" + channel.split("#-")[1];
    }

    this.telegramHttpService.AddChannel(this.selectedBot!.id, channel).subscribe(x => {
      this.selectedBot!.linkedAuthUnits ??= [];
      this.selectedBot!.linkedAuthUnits.splice(0,0, x);
      this.channelId!.nativeElement!.value = "";
      this.globalAlertService.createAlertBannerModel("Erfolg", `Der Channel wurde erfolgreich hinzugefügt.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Es ist ein Fehler beim hinzufügen des Channels aufgetreten.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  EditChannel(channel: AuthUnit) {
    this.companyHttpService.GetCompanyShards().subscribe(shards => {
      this.channelConfig = new TelegramChannelConfiguration(channel, shards);

      this.telegramHttpService.GetChannelSettings(this.channelConfig.channel.id).subscribe(x => {
        this.channelConfig!.MapFetch(x);
      }, error => {
        console.error(error);
        this.globalAlertService.createAlertBannerModel("Fehler", `Beim Laden ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
        this.globalAlertService.show();
      })

    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Beim Laden ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  SaveChannel(): void {
    if (this.channelConfig!.statusUpdateEnabled && this.channelConfig!.interval < 10) {
      this.globalAlertService.createAlertBannerModel("Fehler", "Das kleinste mögliche Intervall sind 10 Minuten.", AlertLevel.error, 2000);
      this.globalAlertService.show();
      return;
    }

    this.telegramHttpService.SetChannelSettings(this.channelConfig!).subscribe(x => {
      this.channelConfig = undefined;
      this.globalAlertService.createAlertBannerModel("Speichern erfolgreich", `Das Speichern der Einstellungen war erfolgreich.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Beim Speichern ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  DeleteChannel(channel: AuthUnit): void {
    let confirmation = confirm("Folgenden Telegram-Channel sicher löschen:\n" + `${channel.id} ${channel.name}`);
    if (!confirmation) return;

    this.telegramHttpService.DeleteChannel(channel.id).subscribe(x => {
      this.selectedBot!.linkedAuthUnits!.splice(this.selectedBot!.linkedAuthUnits!.indexOf(channel), 1);
      this.globalAlertService.createAlertBannerModel("Löschen erfolgreich", `Der Telegram-Channel wurde erfolgreich gelöscht.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Fehler beim Löschen des Telegram-Channels aufgetreten.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  LoadData(): void {
    this.telegramHttpService.ListBots().subscribe(bots => {
      this.error = undefined;
      this.bots = bots;
      if (this.bots.length > 0) {
        this.selectedBot = this.bots[0];
      }
    }, error => {
      this.error = error;
      console.error(error);
    });
  }

  ngOnInit(): void {
    this.LoadData();
  }

  protected readonly HelpTypeLabel = HelpTypeLabel;
}
