import {Component, Input} from '@angular/core';
import {SettingsPageComponent} from "../../../../../x-components/settings/settings-page/settings-page.component";
import {Routes} from "../../../../../enums/routes";
import {AutomationRoutes} from "../../../x-models/x-enums/automation-routes";
import {SettingsInputElementModel} from "../../../../../models/settings-input-element-model";
import {ActivatedRoute, Router} from "@angular/router";
import {PermissionService} from "../../../../../_auth/permission.service";
import {WorkflowHttpService} from "../../../_services/workflow-http.service";
import {GlobalAlertService} from "../../../../../_services/global-alert.service";
import {TransactionWorkflow} from "../../../_models/WorkflowTargets/transaction-workflow";
import {AlertLevel} from "../../../../../enums/alert-level";
import {ModelBase} from "../../../../../models/model-base";

@Component({
  selector: 'app-transaction-workflow-settings',
  templateUrl: '../../../../../x-components/settings/settings-page/settings-page.component.html',
  styleUrl: './transaction-workflow-settings.component.scss'
})
export class TransactionWorkflowSettingsComponent extends SettingsPageComponent {
  public redirectRoute: string = `${Routes.AutomationModule}/${AutomationRoutes.Workflows}`;
  public settingsInputElementModel: { [key: string]: SettingsInputElementModel } = {
    newWorkflowName: new SettingsInputElementModel()
  }

  constructor(route: ActivatedRoute, permissionService: PermissionService, private workflowHttpService: WorkflowHttpService,
              router: Router, gas: GlobalAlertService) {
    super(route, permissionService, router, gas);

    this.settingsInputElementModel.newWorkflowName.propertyTitle = "Name";
    this.settingsInputElementModel.newWorkflowName.requiredPermission = permissionService.Automation_Workflow_SetValue();

    this.deletePermission = permissionService.CheckPermission(permissionService.Automation_Workflow_Delete());
  }

  public model?: ModelBase;
  public timeoutClick: boolean = false;

  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.workflowHttpService.delete(this.model.id).subscribe({
      next: () => this.globalAlertService.createAndShow("Workflow gelöscht", "Workflow wurde erfolgreich gelöscht", AlertLevel.success)
    });
  }

  @Input() saveChanges = () => {
    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveValueProperty("Name", this.settingsInputElementModel.newWorkflowName, (id, property, value) => {
      return this.workflowHttpService.setValue(id, property, value);
    }));

    if (!this.changeRequestRequired()) return;

    this.executeQueueWrapper();
  }

  @Input() loadInputModel: Function = (id: string) => {
    this.workflowHttpService.get(id).subscribe({
      next: workflow => {
        // @ts-ignore
        this.model = workflow as ModelBase;
        this.settingsInputElementModel.newWorkflowName.originalPropertyValue = this.settingsInputElementModel.newWorkflowName.changeableProperties.newPropertyValue = workflow.name;
      }
    })
  }
}
