import {Component} from '@angular/core';
import {TransactionWorkflowFilter} from "../../../../_models/WorkflowTargets/transaction-workflow-filter";
import {NgSelectModule} from "@ng-select/ng-select";
import {GetTypes} from "../../../../../../helpers/enum-helpers";
import {
  TransactionWorkflowFilterTarget, TransactionWorkflowFilterTargetsLabels
} from "../../../../_models/WorkflowTargets/transaction-workflow-filter-target";
import {queue} from "../../../../../../helpers/delay-helper";
import {FormsModule} from "@angular/forms";
import {WorkflowFilterType, WorkflowFilterTypeLabels} from "../../../../_models/_enums/workflow-filter-type";
import {BookingTypeSelectorComponent} from "../booking-type-selector/booking-type-selector.component";
import {ModelBaseSelectorComponent} from "../model-base-selector/model-base-selector.component";
import {TransactionWorkflowSubComponent} from "../transaction-workflow-sub/transaction-workflow-sub.component";

@Component({
  selector: 'app-transaction-workflow-filter',
  standalone: true,
  imports: [
    NgSelectModule,
    FormsModule,
    BookingTypeSelectorComponent,
    ModelBaseSelectorComponent
  ],
  templateUrl: './transaction-workflow-filter.component.html',
  styleUrl: './transaction-workflow-filter.component.scss'
})
export class TransactionWorkflowFilterComponent extends TransactionWorkflowSubComponent<TransactionWorkflowFilter> {
  constructor() {
    super(TransactionWorkflowFilterTarget);
  }

  protected get target() {
    return this.model.target;
  }

  protected set target(value: TransactionWorkflowFilterTarget) {
    queue().then(() => this.model.target = value);
  }

  private static types = GetTypes(WorkflowFilterType);
  protected get types() {
    return TransactionWorkflowFilterComponent.types;
  }

  protected get type() {
    return this.model.type;
  }

  protected set type(value: WorkflowFilterType) {
    queue().then(() => this.model.type = value);
  }

  protected readonly parseInt = parseInt;
  protected readonly TransactionWorkflowFilterTargets = TransactionWorkflowFilterTarget;
  protected readonly TransactionWorkflowFilterTargetsLabels = TransactionWorkflowFilterTargetsLabels;
  protected readonly WorkflowFilterType = WorkflowFilterType;
  protected readonly WorkflowFilterTypeLabels = WorkflowFilterTypeLabels;
}
