import {Guid} from "../../../../helpers/guid-helper";
import {WorkflowValue} from "./workflow-value";

export abstract class TransactionWorkflowSub {
  id: string = Guid.newGuid();
  values: WorkflowValue[] = [];

  add(value: string) {
    if (this.values.findIndex(x => x.value == value) > -1) return;
    this.values.push(new WorkflowValue(value));
  }

  remove(value: string) {
    this.values = this.values.filter(v => v.value !== value);
  }

  cleanup() {
    this.values = this.values.filter(x => !x.value.isNullOrWhitespace())
  }

  validate(): boolean {
    this.cleanup();
    return this.values.length > 0;
  }
}
