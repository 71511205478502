import {Component} from '@angular/core';
import {PageContainerComponent} from "../../../_components/page-container/page-container.component";
import {HeaderContainerComponent} from "../../../_components/header-container/header-container.component";
import {PageScrollableComponent} from "../../../_components/page-scrollable/page-scrollable.component";
import {GetTypes} from "../../../helpers/enum-helpers";
import {WorkflowTarget, WorkflowTargetLabels} from "../_models/_enums/workflow-target";
import {NgClass} from "@angular/common";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {queue} from "../../../helpers/delay-helper";
import {TransactionWorkflow} from "../_models/WorkflowTargets/transaction-workflow";
import {TransactionWorkflowFilter} from "../_models/WorkflowTargets/transaction-workflow-filter";
import {TransactionWorkflowComponent} from "./transaction-workflow/transaction-workflow.component";

@Component({
  selector: 'app-workflows',
  standalone: true,
  imports: [
    PageContainerComponent,
    HeaderContainerComponent,
    NgClass,
    NgSelectModule,
    FormsModule,
    TransactionWorkflowComponent,
    PageScrollableComponent
  ],
  templateUrl: './workflows.component.html',
  styleUrl: './workflows.component.scss'
})
export class WorkflowsComponent {
  types = GetTypes(WorkflowTarget);

  private _selectedType: WorkflowTarget = WorkflowTarget.TransactionFinished;
  protected get selectedType() {
    return this._selectedType;
  }

  protected set selectedType(value: WorkflowTarget) {
    queue().then(() => this._selectedType = value);
  }

  protected readonly WorkflowTargetLabels = WorkflowTargetLabels;
  protected readonly parseInt = parseInt;
  protected readonly WorkflowTarget = WorkflowTarget;
  protected readonly TransactionWorkflow = TransactionWorkflow;
  protected readonly TransactionWorkflowFilter = TransactionWorkflowFilter;
  protected readonly alert = alert;
}
