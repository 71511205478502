<div class="w-full inline-flex my-2 gap-x-2">
  <a class="themed-link h-6 w-6 mt-1.5" (click)="remove()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <path fill="currentColor"
            d="M208.49 191.51a12 12 0 0 1-17 17L128 145l-63.51 63.49a12 12 0 0 1-17-17L111 128L47.51 64.49a12 12 0 0 1 17-17L128 111l63.51-63.52a12 12 0 0 1 17 17L145 128Z"/>
    </svg>
  </a>
  <div class="w-full grid grid-cols-3 gap-x-2">
    <ng-select [(ngModel)]="target" [clearable]="false"
               class="self-start ng-select cheqme-style block rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
      @for (t of targets; track t) {
        <ng-option [value]="parseInt(t)">{{ WorkflowActionTargetLabels[t] }}</ng-option>
      })
    </ng-select>

    @switch (model.target) {
      @case (WorkflowActionTarget.Printer) {
        <app-printer-selector [type]="model.target" [action]="model"/>
      }
      @case (WorkflowActionTarget.Telegram) {
        <app-telegram-selector class="col-span-2" [type]="model.target" [action]="model"/>
      }
    }

    <!--<ng-select [(ngModel)]="type" [clearable]="false"
               class="self-start ng-select cheqme-style block rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
      @for (t of types; track t) {
        <ng-option [value]="parseInt(t)">{{ WorkflowFilterTypeLabels[t] }}</ng-option>
      })
    </ng-select>

    @switch (target) {
      @case (TransactionWorkflowFilterTargets.BookingType) {
        <app-booking-type-selector [filter]="model"/>
      }
      @default {
        <app-model-base-selector [filter]="model" [target]="target"/>
      }
    }-->
  </div>
  <span class="mt-1.5" [class.opacity-0]="last">
    und
  </span>
</div>
