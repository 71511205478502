import {BrowserModule} from '@angular/platform-browser';
import {APP_ID, APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {Router, RouterModule, TitleStrategy} from '@angular/router';
import {AppComponent} from './app.component';
import {NavMenuComponent} from './nav-menu/nav-menu.component';
import {HomeComponent} from './home/home.component';
import {CategoriesComponent} from './categories/categories.component';
import {ItemsComponent} from './items/items-index/items.component';
import {CompositionsComponent} from './compositions/compositions.component';
import {TagsComponent} from './tags/tags.component';
import {LocationsComponent} from './locations/locations.component';
import {ConfigurationsComponent} from './configurations/configurations.component';
import {OrdersComponent} from './orders/orders.component';
import {ErrorBannerComponent} from './x-components/error-banner/error-banner.component';
import {AuthInterceptor} from "./_auth/auth.interceptor";
import {AuthGuard} from "./_auth/auth.guard";
import {SetupIndexComponent} from './setup/index/index.component';
import {SetupGuard} from "./setup/setup.guard";
import {LoginComponent} from './_auth/login/login.component';
import {ErrorInterceptor} from "./_auth/error.interceptor";
import {AdminUserComponent} from "./admin/user/user.component";
import {AdminRoleComponent} from "./admin/role/role.component";
import {OwnerShardComponent} from "./owner/shard/shard.component";
import {OwnerUserComponent} from "./owner/user/user.component";
import {OwnerCompanyComponent} from "./owner/company/company.component";
import {UserComponent} from './selfservice/user/user.component';
import {PermissionService} from "./_auth/permission.service";
import {EmployeeComponent} from './_modules/company/employee/employee.component';
import {SettingsComponent} from './_modules/company/settings/settings.component';
import {SettingsPopUpComponent} from './x-components/settings-pop-up/settings-pop-up.component';
import {AlertBannerComponent} from './x-components/alert-banner/alert-banner.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {EditableHeaderComponent} from './x-components/editable-header/editable-header.component';
import {ItemsSettingsPageComponent} from './items/items-settings-page/items-settings-page.component';
import {GlobalAlertService} from "./_services/global-alert.service";
import {Routes} from "./enums/routes";
import {
  SettingsInputElementComponent
} from './x-components/settings/settings-input-element/settings-input-element.component';
import {SettingsPageComponent} from './x-components/settings/settings-page/settings-page.component';
import {
  CompositionsSettingsPageComponent
} from "./compositions/compositions-settings-page/compositions-settings-page.component";
import {TagsSettingsPageComponent} from './tags/tags-settings-page/tags-settings-page.component';
import {LocationSettingsComponent} from './locations/location-settings/location-settings.component';
import {EventSettingsComponent} from './management/events/event-settings/event-settings.component';
import {TelegramComponent} from './_modules/company/telegram/telegram.component';
import {XMenuDevicesComponent} from './_modules/signalRTracker/x-menu-devices/x-menu-devices.component';
import {DevicesComponent} from "./_modules/signalRTracker/devices/devices.component";
import {CategorySettingsComponent} from './categories/category-settings/category-settings.component';
import {ShardSettingsComponent} from './owner/shard/shard-settings/shard-settings.component';
import {CompanySettingComponent} from './owner/company/company-setting/company-setting.component';
import {
  DeviceSettingsPageComponent
} from './_modules/signalRTracker/devices/device-settings-page/device-settings-page.component';
import {OwnerEnvironmentSettingsComponent} from './owner/environment-settings/owner-environment-settings.component';
import {SuppliersComponent} from './_modules/company/suppliers/suppliers.component';
import {CompositionsTypeComponent} from './compositions-type/compositions-type.component';
import {EmployeeDetailsComponent} from './_modules/company/employee-details/employee-details.component';
import {PatchesComponent} from './admin/patches/patches.component';
import {EmployeePanelComponent} from './_modules/employee-panel/employee-panel.component';
import {
  CompostionTypeSettingsComponent
} from './compositions-type/compostion-type-settings/compostion-type-settings.component';
import {SupplierSettingsComponent} from './_modules/company/suppliers/supplier-settings/supplier-settings.component';
import {EmployeeSettingsComponent} from './_modules/company/employee/employee-settings/employee-settings.component';
//import {AnalyticToolsModule} from "./_modules/analytic-tools/analytic-tools.module";
import {CommonModule, registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {CompanyComponent} from "./_modules/company/company.component";
import {DateInterceptor} from "./x-interceptors/date.interceptor";
import {XComponentsModule} from "./_modules/x-components/x-components.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {
  EmploymentRelationshipSettingsComponent
} from "./_modules/company/employment-relationships/employment-relationship-settings/employment-relationship-settings.component";
import {CompanyRoutes} from "./_modules/company/x-models/x-enums/company-routes";
import {
  EmployeeAttributesSettingsComponent
} from "./_modules/company/employee-attributes/employee-attributes-settings/employee-attributes-settings.component";
import {AutomationModule} from "./_modules/automation/automation.module";
import {AutomationComponent} from "./_modules/automation/automation.component";
import {ChangelogComponent} from './changelog/changelog.component';
import {RichTextEditorModule} from "@syncfusion/ej2-angular-richtexteditor";
import {MarkdownModule} from "ngx-markdown";
import {CompanyModule} from "./_modules/company/company.module";
import {
  JsonViewerPopupComponent
} from './_modules/signalRTracker/_components/json-viewer-popup/json-viewer-popup.component';
import {NgxJsonViewerModule} from "ngx-json-viewer";
import {NavbarOfficeComponent} from './x-components/navbar-office/navbar-office.component';
import {NavbarOfficeItemComponent} from './x-components/navbar-office/navbar-office-item/navbar-office-item.component';
import {NavbarContainerComponent} from './x-components/navbar-base/navbar-container/navbar-container.component';
import {NavbarItemLargeComponent} from './x-components/navbar-base/navbar-item-large/navbar-item-large.component';
import {
  NavbarItemSmallContainerComponent
} from './x-components/navbar-base/navbar-item-small-container/navbar-item-small-container.component';
import {NavbarItemSmallComponent} from './x-components/navbar-base/navbar-item-small/navbar-item-small.component';
import {NavbarInventoryComponent} from './x-components/navbar-inventory/navbar-inventory.component';
import {NavbarAutomationComponent} from './_modules/automation/navbar-automation/navbar-automation.component';
import {NavbarLocationComponent} from './_modules/signalRTracker/navbar-devices/navbar-location.component';
import {NavbarCompanyComponent} from './_modules/company/navbar-company/navbar-company.component';
import {NavbarAdminComponent} from './x-components/navbar-admin/navbar-admin.component';
import {NavbarOwnerComponent} from './x-components/navbar-owner/navbar-owner.component';
import {NavbarHelpComponent} from './x-components/navbar-help/navbar-help.component';
import {InfoMessageComponent} from './x-components/info-message/info-message.component';
import {BottomMenuComponent} from './x-components/bottom-menu/bottom-menu.component';
import {CookieSettingsComponent} from './x-components/cookie-settings/cookie-settings.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {
  NavbarAnalysisComponent
} from "./_modules/analytics/common/components/navigation/navbar-analysis-component/navbar-analysis.component";
import {IndexComponent as AnalysisIndexComponent} from "./_modules/analytics/views/index/index.component";
import {NavbarLegalComponent} from "./_modules/legal/navbar-legal/navbar-legal.component";
import {LegalComponent} from "./_modules/legal/legal.component";
import {NavbarUserComponent} from './x-components/navbar-user/navbar-user.component';
import {ProfileComponent} from './authentication/profile/profile.component';
import {LocationComponent} from "./_modules/location/location.component";
import {LocationRoutes} from "./_modules/location/_models/_enums/location-routes";
import {PrinterSettingsComponent} from "./_modules/location/printer-settings/printer-settings.component";
import {OwnerComponent} from "./_modules/owner/owner.component";
import {FullscreenLoaderComponent} from "./x-components/fullscreen-loader/fullscreen-loader.component";
import './_services/rxjs-extensions';
import './_services/date-extensions';
import './_services/number-extensions';
import './_services/string-extensions';
import './_services/http-response-extensions';
import {HelpIntegrationComponent} from "./x-components/help-integration/help-integration.component";
import * as Sentry from "@sentry/angular";
import {SelfServiceComponent} from "./_modules/self-service/self-service.component";
import {CheqmeTitleStrategy} from "./cheqme-title.strategy";
import {FormContainerComponent} from "./_components/form-container/form-container.component";
import {PageContainerComponent} from "./_components/page-container/page-container.component";
import {HeaderContainerComponent} from "./_components/header-container/header-container.component";
import {PageScrollableComponent} from "./_components/page-scrollable/page-scrollable.component";
import {SortingIndicatorComponent} from "./x-components/sorting-indicator/sorting-indicator.component";
import {InventoryComponent} from "./_modules/inventory/inventory.component";
import {AutomationRoutes} from "./_modules/automation/x-models/x-enums/automation-routes";
import {
  TransactionWorkflowSettingsComponent
} from "./_modules/automation/workflows/transaction-workflow/transaction-workflow-settings/transaction-workflow-settings.component";

registerLocaleData(localeDe, 'de', localeDeExtra)

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CategoriesComponent,
    ItemsComponent,
    CompositionsComponent,
    TagsComponent,
    LocationsComponent,
    ConfigurationsComponent,
    OrdersComponent,
    OwnerShardComponent,
    OwnerUserComponent,
    OwnerCompanyComponent,
    ErrorBannerComponent,
    SetupIndexComponent,
    LoginComponent,
    AdminRoleComponent,
    AdminUserComponent,
    UserComponent,
    EmployeeComponent,
    SettingsComponent,
    SettingsPopUpComponent,
    AlertBannerComponent,
    EditableHeaderComponent,
    ItemsSettingsPageComponent,
    SettingsInputElementComponent,
    SettingsPageComponent,
    CompositionsSettingsPageComponent,
    TagsSettingsPageComponent,
    LocationSettingsComponent,
    EventSettingsComponent,
    TelegramComponent,
    XMenuDevicesComponent,
    DevicesComponent,
    CategorySettingsComponent,
    ShardSettingsComponent,
    CompanySettingComponent,
    OwnerEnvironmentSettingsComponent,
    DeviceSettingsPageComponent,
    SuppliersComponent,
    CompositionsTypeComponent,
    CompostionTypeSettingsComponent,
    SupplierSettingsComponent,
    EmployeeSettingsComponent,
    EmployeeDetailsComponent,
    PatchesComponent,
    EmploymentRelationshipSettingsComponent,
    EmployeeAttributesSettingsComponent,
    ChangelogComponent,
    JsonViewerPopupComponent,
    NavbarOfficeComponent,
    NavbarOfficeItemComponent,
    NavbarContainerComponent,
    NavbarItemLargeComponent,
    NavbarItemSmallContainerComponent,
    NavbarItemSmallComponent,
    NavbarInventoryComponent,
    NavbarAutomationComponent,
    NavbarLocationComponent,
    NavbarLegalComponent,
    NavbarCompanyComponent,
    NavbarAdminComponent,
    NavbarOwnerComponent,
    NavbarAnalysisComponent,
    NavbarHelpComponent,
    InfoMessageComponent,
    BottomMenuComponent,
    CookieSettingsComponent,
    NavbarUserComponent,
    ProfileComponent,
    PrinterSettingsComponent,
    TransactionWorkflowSettingsComponent
  ],
  exports: [
    RouterModule,
    NavbarContainerComponent,
    NavbarItemLargeComponent,
    SortingIndicatorComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    FormsModule,
    RichTextEditorModule,
    RouterModule.forRoot([
        {
          path: Routes.SetupIndex, component: SetupIndexComponent, // 'setup/index'
          canActivate: [SetupGuard],
          title: "Setup"
        },
        {
          path: Routes.Login, component: LoginComponent,
          title: "Anmelden"
        }, // 'login'
        {
          path: Routes.Home, component: HomeComponent, //''
          canActivate: [AuthGuard],
          title: "Startseite"
        },
        {
          path: Routes.AuthenticationProfile, component: ProfileComponent,
          canActivate: [AuthGuard],
          title: "Profil"
        },
        {
          path: Routes.Categories, component: CategoriesComponent, //'categories'
          canActivate: [AuthGuard], data: {roles: PermissionService.Permissions_CategoryComponent().default},
          title: "Kategorien"
        },
        {
          path: Routes.Items, component: ItemsComponent, // 'items'
          canActivate: [AuthGuard], data: {roles: PermissionService.Permissions_ItemComponent().default},
          title: "Items"
        },
        {
          path: Routes.Compositions, component: CompositionsComponent, // 'compositions'
          canActivate: [AuthGuard], data: {roles: PermissionService.Permissions_CompositionsComponent().default},
          title: "Artikel"
        },
        {
          path: Routes.CompositionTypes,
          component: CompositionsTypeComponent,
          canActivate: [AuthGuard],
          data: {roles: PermissionService.Permissions_CompositionTypesComponent().default},
          title: "Artikel-Kategorien"
        },
        {
          path: Routes.Tags, component: TagsComponent, // 'tags'
          canActivate: [AuthGuard], data: {roles: PermissionService.Permissions_TagsComponent().default},
          title: "Tags"
        },
        {
          path: Routes.Locations, component: LocationsComponent, // locations
          canActivate: [AuthGuard], data: {roles: PermissionService.Permissions_LocationsComponent().default},
          title: "Verkaufspunkte"
        },
        {
          path: Routes.Configurations,
          component: ConfigurationsComponent, // 'configurations'
          canActivate: [AuthGuard],
          data: {roles: PermissionService.Permissions_ConfigurationsComponent().default},
          title: "Preisprofile"
        },
        {
          path: Routes.Orders, component: OrdersComponent, //'orders'
          canActivate: [AuthGuard], data: {roles: PermissionService.Permissions_OrderComponent().default},
          title: "Verkäufe"
        },
        {
          path: Routes.DeviceOverview, component: DevicesComponent, // 'devices/overview'
          canActivate: [AuthGuard], data: {roles: PermissionService.Permissions_DevicesComponent().default},
          title: "Geräte"
        },
        {
          path: Routes.CompanyEmployee, component: EmployeeComponent, // 'company/employee'
          canActivate: [AuthGuard], data: {roles: PermissionService.Permissions_EmployeeComponent().default},
          title: "Mitarbeiter"
        },
        {
          path: Routes.CompanyEmployeeDetails,
          component: EmployeeDetailsComponent, // 'company/employee/details',
          canActivate: [AuthGuard],
          data: {roles: PermissionService.Permissions_EmployeeDetailsComponent().default},
          title: "Mitarbeiterprofil"
        },
        {
          path: Routes.CompanyTelegram, component: TelegramComponent, // 'company/telegram'
          canActivate: [AuthGuard], data: {roles: PermissionService.Permissions_TelegramComponent().default},
          title: "Telegram"
        },
        {
          path: Routes.CompanySupplier, component: SuppliersComponent, // 'company/supplier'
          canActivate: [AuthGuard], data: {roles: PermissionService.Permissions_SuppliersComponent().default},
          title: "Lieferanten"
        },
        {
          path: Routes.CompanySupplierSettings,
          component: SupplierSettingsComponent, // 'company/supplier/settings'
          canActivate: [AuthGuard],
          data: {roles: PermissionService.Permissions_SuppliersComponent().default},
          title: "Lieferant"
        },
        {
          path: Routes.CompanySettings, component: SettingsComponent, // 'company/settings'
          canActivate: [AuthGuard], data: {roles: PermissionService.admin.default},
          title: "Unternehmenseinstellungen"
        },
        {
          path: Routes.AdminUsers, component: AdminUserComponent, //'admin/users'
          canActivate: [AuthGuard], data: {roles: PermissionService.admin.default},
          title: "Benutzer"
        },
        {
          path: Routes.AdminRoles, component: AdminRoleComponent, //'admin/roles'
          canActivate: [AuthGuard], data: {roles: PermissionService.admin.default},
          title: "Rollen"
        },
        {
          path: Routes.AdminPatches, component: PatchesComponent, //'admin/patches'
          canActivate: [AuthGuard], data: {roles: PermissionService.admin.default},
          title: "Patches"
        },
        {
          path: Routes.OwnerShards, component: OwnerShardComponent, //'owner/shards'
          canActivate: [AuthGuard], data: {roles: PermissionService.owner.default},
          title: "Standorte"
        },
        {
          path: Routes.OwnerUsers, component: OwnerUserComponent, //'owner/users'
          canActivate: [AuthGuard], data: {roles: PermissionService.owner.default},
          title: "Benutzer"
        },
        {
          path: Routes.OwnerCompanies, component: OwnerCompanyComponent, //'owner/companies'
          canActivate: [AuthGuard], data: {roles: PermissionService.owner.default},
          title: "Unternehmen"
        },
        {
          path: Routes.OwnerEnvironmentSettings, component: OwnerEnvironmentSettingsComponent,
          canActivate: [AuthGuard], data: {roles: PermissionService.owner.default},
          title: "Environment Einstellungen"
        },
        {
          path: Routes.CompositionSettings,
          component: CompositionsSettingsPageComponent, // 'compositions'
          canActivate: [AuthGuard],
          data: {roles: PermissionService.inventory_composition_get.default},
          title: "Artikel"
        },
        {
          path: Routes.CompositionTypeSettings,
          component: CompostionTypeSettingsComponent,
          canActivate: [AuthGuard],
          data: {roles: PermissionService.inventory_compositiontype_get.default},
          title: "Artikel-Kategorie"
        },
        {
          path: Routes.ItemSettings,
          component: ItemsSettingsPageComponent, // "items/settings"
          canActivate: [AuthGuard],
          data: {roles: PermissionService.inventory_item_get.default},
          title: "Item"
        },
        {
          path: Routes.TagSettings,
          component: TagsSettingsPageComponent, // "tags/settings"
          canActivate: [AuthGuard],
          data: {roles: PermissionService.inventory_tag_get.default},
          title: "Tag"
        },
        {
          path: Routes.LocationSettings,
          component: LocationSettingsComponent, // "locations/settings"
          canActivate: [AuthGuard],
          data: {roles: PermissionService.inventory_location_get.default},
          title: "Verkaufspunkt"
        },
        {
          path: Routes.EventSettings, component: EventSettingsComponent, // "events/settings"
          canActivate: [AuthGuard], data: {roles: PermissionService.inventory_event_get.default},
          title: "Event"
        },
        {
          path: Routes.CategorySettings,
          component: CategorySettingsComponent,
          canActivate: [AuthGuard],
          data: {roles: PermissionService.inventory_category_get.default},
          title: "Kategorie"
        },
        {
          path: Routes.DeviceSettings,
          component: DeviceSettingsPageComponent,
          canActivate: [AuthGuard],
          data: {roles: PermissionService.device_information_get.default},
          title: "Gerät"
        },
        {
          path: Routes.OwnerShardSettings, component: ShardSettingsComponent,
          canActivate: [AuthGuard], data: {roles: PermissionService.owner.default},
          title: "Standort"
        },
        {
          path: Routes.OwnerCompanySettings, component: CompanySettingComponent,
          canActivate: [AuthGuard], data: {roles: PermissionService.owner.default},
          title: "Unternehmen"
        },
        {
          path: Routes.CompanyEmployeeSettings,
          component: EmployeeSettingsComponent,
          canActivate: [AuthGuard],
          data: {roles: PermissionService.company_employee_get.default},
          title: "Mitarbeiter"
        },
        {
          path: Routes.Changelog, component: ChangelogComponent,
          canActivate: [AuthGuard], data: {roles: PermissionService.Permissions_ChangelogComponent().default},
          title: "Changelog"
        },
        {
          path: Routes.AutomationModule + "/" + AutomationRoutes.WorkflowsTransactionSettings,
          component: TransactionWorkflowSettingsComponent,
          canActivate: [AuthGuard], data: {roles: PermissionService.automation_workflow_get.default},
          title: "Workflow Einstellungen"
        },


        // modules
        {
          path: Routes.CompanyModule + "/" + CompanyRoutes.EmployeeRelationshipsSettings,
          component: EmploymentRelationshipSettingsComponent,
          canActivate: [AuthGuard],
          data: {roles: PermissionService.company_employmentrelationship_get.default},
          title: "Arbeitsverhältnis"
        },
        {
          path: Routes.CompanyModule + "/" + CompanyRoutes.EmployeeAttributesSettings,
          component: EmployeeAttributesSettingsComponent,
          canActivate: [AuthGuard],
          data: {roles: PermissionService.company_employeeattribute_get.default},
          title: "Mitarbeiterattribut"
        },
        /*{
            path: Routes.Analytics,// 'analytics'
            loadChildren: () => import('./_modules/analytic-tools/analytic-tools.module').then(m => m.AnalyticToolsModule),
            component: AnalyticToolsIndexComponent,
            canActivate: [AuthGuard],
            data: {roles: PermissionService.Permissions_AnalysisComponent().default}
        },*/
        {
          path: Routes.Analytics, // 'analytics'
          loadChildren: () => import('./_modules/analytics/analytics.module').then(m => m.AnalyticsModule),
          component: AnalysisIndexComponent,
          title: "Analyse"
        },
        {
          path: Routes.CompanyModule,
          loadChildren: () => import('./_modules/company/company.module').then(x => x.CompanyModule),
          component: CompanyComponent
        },
        {
          path: Routes.AutomationModule,
          loadChildren: () => import('./_modules/automation/automation.module').then(x => x.AutomationModule),
          component: AutomationComponent
        },
        {
          path: Routes.LocationModule + "/" + LocationRoutes.PrinterSettings,
          component: PrinterSettingsComponent,
          canActivate: [AuthGuard],
          data: {roles: PermissionService.device_printer_get.default}
        },
        {
          path: Routes.LocationModule,
          loadChildren: () => import('./_modules/location/location.module').then(x => x.LocationModule),
          component: LocationComponent
        },
        {
          path: Routes.SelfServiceModule,
          loadChildren: () => import('./_modules/self-service/self-service.module').then(x => x.SelfServiceModule),
          component: SelfServiceComponent
        },
        {
          path: Routes.InventoryModule,
          loadChildren: () => import('./_modules/inventory/inventory.module').then(x => x.InventoryModule),
          component: InventoryComponent
        },
        {
          path: Routes.EmployeePanel,
          loadChildren: () => import('./_modules/employee-panel/employee-panel.module').then(x => x.EmployeePanelModule),
          component: EmployeePanelComponent,
          canActivate: [AuthGuard],
          data: {roles: PermissionService.ep_base.default}
        },
        /*{
          path: Routes.Feedback,
          loadChildren: () => import('./_modules/feedback/feedback.module').then(x => x.FeedbackModule),
        },*/
        {
          path: Routes.TablePlanner,
          loadChildren: () => import('./_modules/table-booking/table-booking.module').then(x => x.TableBookingModule),
        },
        {
          path: Routes.LegalModule,
          loadChildren: () => import('./_modules/legal/legal.module').then(x => x.LegalModule),
          component: LegalComponent
        },
        {
          path: Routes.OwnerGeolocationTracking,
          loadChildren: () => import('./_modules/geolocation-tracker/geolocation-tracker.module').then(x => x.GeolocationTrackerModule),
        },
        {
          path: Routes.OwnerModule,
          loadChildren: () => import('./_modules/owner/owner.module').then(x => x.OwnerModule),
          component: OwnerComponent
        },
        {
          path: '**', redirectTo: '/', pathMatch: "full"
        }
      ],
      //  {enableTracing: true}
    ),
    AutomationModule,
    XComponentsModule,
    NgSelectModule,
    CompanyModule,
    NgxJsonViewerModule,
    MatCheckboxModule,
    MarkdownModule.forRoot(),
    FullscreenLoaderComponent,
    HelpIntegrationComponent,
    FormContainerComponent,
    PageContainerComponent,
    HeaderContainerComponent,
    PageScrollableComponent,
    SortingIndicatorComponent
  ],
  providers: [
    {
      provide: TitleStrategy,
      useClass: CheqmeTitleStrategy
    },
    // sentry (debugging)
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true
    },
    GlobalAlertService,
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    }, {
      provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true
    }, {
      provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "de-DE"
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
  static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
