import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CompositionTypeNl} from "../models/composition-type/composition-type-nl";
import {CompositionTypeDto} from "../models/composition-type/composition-type-dto";
import {ServerEndpoints} from "../server.endpoints";
import {VATRate} from "../models/common/vat-rate";

@Injectable({
  providedIn: 'root'
})
export class CompositionTypeHttpService {

  constructor(@Inject("BASE_URL") private baseUrl: string, private httpClient: HttpClient) {
  }

  ListNl(): Observable<CompositionTypeNl[]> {
    return this.httpClient.get<CompositionTypeNl[]>(this.baseUrl + ServerEndpoints.Inventory_CompositionType_ListNl);
  }

  Add(name: string): Observable<CompositionTypeDto> {
    return this.httpClient.post<CompositionTypeDto>(this.baseUrl + ServerEndpoints.Inventory_CompositionType_Add, {
      name: name
    })
  }

  List(): Observable<CompositionTypeDto[]> {
    return this.httpClient.get<CompositionTypeDto[]>(this.baseUrl + ServerEndpoints.Inventory_CompositionType_List);
  }

  listByIds(ids: string[]): Observable<CompositionTypeNl[]> {
    return this.httpClient.post<CompositionTypeNl[]>(this.baseUrl + ServerEndpoints.Inventory_CompositionType_List, ids);
  }

  get(id: string): Observable<CompositionTypeDto> {
    return this.httpClient.get<CompositionTypeDto>(this.baseUrl + ServerEndpoints.Inventory_CompositionType_Get + "?id=" + id)
  }

  delete(id: string) {
    return this.httpClient.delete(this.baseUrl + ServerEndpoints.Inventory_CompositionType_Delete + "?id=" + id);
  }

  SetCompositionType(compositionType: string, compositions: string[]): Observable<CompositionTypeDto | undefined> {
    return this.httpClient.put<CompositionTypeDto | undefined>(this.baseUrl + ServerEndpoints.Inventory_CompositionType_SetCompositionType, {
      compositionType: compositionType,
      compositions: compositions
    });
  }

  changeName(id: string, name: string): Observable<CompositionTypeDto> {
    return this.httpClient.put<CompositionTypeDto>(this.baseUrl + ServerEndpoints.Inventory_CompositionType_ChangeName, {
      Id: id,
      Name: name
    });
  }

  changeVat(id: string, vat: VATRate): Observable<CompositionTypeDto> {
    return this.httpClient.put<CompositionTypeDto>(this.baseUrl + ServerEndpoints.Inventory_CompositionType_ChangeVat + "?id=" + id + "&vat=" + vat, {});
  }

  setValue(id: string, name: string, value: string): Observable<CompositionTypeDto> {
    return this.httpClient.post<CompositionTypeDto>(`${this.baseUrl}${ServerEndpoints.Inventory_CompositionType_SetValue}/${id}`, {
      name: name,
      value: value
    })
  }
}
