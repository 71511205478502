import {IWorkflow} from "../i-workflow";
import {TransactionWorkflowFilter} from "./transaction-workflow-filter";
import {Guid} from "../../../../helpers/guid-helper";
import {TransactionWorkflowAction} from "./transaction-workflow-action";

export class TransactionWorkflow implements IWorkflow {
  id: string = Guid.newGuid();
  name: string | undefined;

  filters: TransactionWorkflowFilter[] = [];

  addFilter() {
    let filter = new TransactionWorkflowFilter();
    this.filters.push(filter);
  }

  actions: TransactionWorkflowAction[] = [];

  addAction() {
    let action = new TransactionWorkflowAction();
    this.actions.push(action);
  }

  cleanup() {
    this.filters = this.filters.filter(x => x.validate());
    this.actions = this.actions.filter(x => x.validate());
  }

  validate(): boolean {
    this.cleanup();
    return this.filters.length > 0 && this.actions.length > 0;
  }
}
