import {Component, Input} from '@angular/core';
import {TransactionWorkflowAction} from "../../../../_models/WorkflowTargets/transaction-workflow-action";
import {WorkflowActionTarget, WorkflowActionTargetLabels} from "../../../../_models/_enums/workflow-action-target";
import {PrinterHttpService} from "../../../../../location/_services/printer-http.service";
import {PrinterDto} from "../../../../../location/_models/printer-dto";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {queue} from "../../../../../../helpers/delay-helper";

@Component({
  selector: 'app-printer-selector',
  standalone: true,
  imports: [
    NgSelectModule,
    FormsModule
  ],
  templateUrl: './printer-selector.component.html',
  styleUrl: './printer-selector.component.scss'
})
export class PrinterSelectorComponent {
  constructor(private printer: PrinterHttpService) {
  }

  @Input() set type(type: WorkflowActionTarget) {
    this.printers = [];
    if (this._action !== undefined) this._action.values = [];
    if (type !== WorkflowActionTarget.Printer) return;
    this.load();
  }

  protected _action: TransactionWorkflowAction | undefined;
  @Input() set action(action: TransactionWorkflowAction | undefined) {
    this._action = action;
    if (this._action !== undefined) this._action.values = [];
  }

  protected printers: PrinterDto[] = [];

  private load() {
    this.printer.list().subscribe({
      next: printers => {
        this.printers = printers;
      }
    })
  }

  protected selected: PrinterDto | null = null;

  protected onSelected(selected: PrinterDto | null) {
    if (selected != undefined && this._action !== undefined) {
      this._action.add(selected.id);
    }
    queue().then(() => this.selected = null);
  }

  protected findModel(id: string): PrinterDto {
    let printer = this.printers.find(printer => printer.id === id) as PrinterDto;
    return printer;
  }

  protected getSortedModels(): PrinterDto[] {
    if (this._action === undefined) return [];
    return this._action.values.map(v => this.findModel(v.value)).sort((a, b) => a.name.localeCompare(b.name));
  }

  protected readonly WorkflowActionTargetLabels = WorkflowActionTargetLabels;
  protected readonly parseInt = parseInt;
}
