import {Directive, EventEmitter, Input, Output} from "@angular/core";
import {TransactionWorkflowSub} from "../../../../_models/WorkflowTargets/transaction-workflow-sub";
import {GetTypes} from "../../../../../../helpers/enum-helpers";

@Directive()
export class TransactionWorkflowSubComponent<T extends TransactionWorkflowSub> {
  constructor(t: Record<string, string | number>) {
    this.targets = GetTypes(t);
  }

  @Input() model!: T;
  @Input() last: boolean = false;
  @Output() onRemove = new EventEmitter<T>();

  protected remove() {
    this.onRemove.emit(this.model);
  }

  protected targets: string[];
}
