import {Component, Input} from '@angular/core';
import {TransactionWorkflowFilter} from "../../../../_models/WorkflowTargets/transaction-workflow-filter";
import {NgSelectModule} from "@ng-select/ng-select";
import {
  TransactionWorkflowFilterTarget
} from "../../../../_models/WorkflowTargets/transaction-workflow-filter-target";
import {CompositionHttpService} from "../../../../../../http-requests/composition-http.service";
import {CompositionTypeHttpService} from "../../../../../../http-requests/composition-type-http.service";
import {Observable} from "rxjs";
import {ModelBase} from "../../../../../../models/model-base";
import {queue} from "../../../../../../helpers/delay-helper";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-model-base-selector',
  standalone: true,
  imports: [
    NgSelectModule,
    FormsModule
  ],
  templateUrl: './model-base-selector.component.html',
  styleUrl: './model-base-selector.component.scss'
})
export class ModelBaseSelectorComponent {
  constructor(private compositions: CompositionHttpService,
              private compositionType: CompositionTypeHttpService) {
  }

  protected _filter: TransactionWorkflowFilter | undefined;
  @Input() set filter(filter: TransactionWorkflowFilter | undefined) {
    this._filter = filter;
    if (this._filter !== undefined) this._filter.values = [];
  }

  get filter() {
    return this._filter;
  }

  @Input() set target(value: TransactionWorkflowFilterTarget) {
    this.models = [];
    this.load(value);
    if (this._filter !== undefined) this._filter.values = [];
  }

  protected models: ModelBase[] = [];

  onSelected(id: string | undefined) {
    if (id != undefined && this._filter != undefined) {
      this._filter.add(id);
    }
    queue().then(() => this.selected = null);
    // ().then(() => this.selected = undefined);
  }

  protected selected: string | null = null;

  private load(target: TransactionWorkflowFilterTarget) {
    let observable: Observable<any>
    switch (target) {
      case TransactionWorkflowFilterTarget.Composition:
        observable = this.compositions.list();
        break;
      case TransactionWorkflowFilterTarget.CompositionType:
        observable = this.compositionType.List();
        break;
      default:
        throw new Error('Unknown target: ' + target);
    }

    observable.subscribe({
      next: (models: ModelBase[]) => {
        this.models = models;
      }
    })
  }

  protected findModel(id: string): ModelBase {
    return this.models.find(m => m.id === id) as ModelBase;
  }

  protected getSortedModels(): ModelBase[] {
    if (this._filter === undefined) return [];
    return this._filter.values.map(v => this.findModel(v.value)).sort((a, b) => a.name.localeCompare(b.name));
  }
}
