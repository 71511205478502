import {Guid} from "../../../../helpers/guid-helper";

export class WorkflowValue {
  constructor(value: string) {
    this.value = value;
  }

  id: string = Guid.newGuid();
  value: string;
}
