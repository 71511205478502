<layout-page-container class="flex flex-col">
  <layout-header-container title="Workflows"/>
  <div class="h-full relative">
    <!-- small screens
    <div class="absolute lg:hidden inset-0">
      <h3 class="fw-thin display-6 absolute top-1/3 -translate-y-1/2 left-1/2 -translate-x-1/2 text-center">
        Bildschirmbreite nicht
        ausreichend. Bitte größerers Gerät verwenden.</h3>
    </div>-->

    <!-- configuration -->
    <div class="flex flex-col space-y-2 absolute inset-0 !bottom-8">
      <!-- workflow type selection -->
      <div class="border-b border-gray-200 lg:border-transparent pb-2 lg:pb-0">
        <div class="mt-3 sm:mt-4">
          <div class="grid grid-cols-1 lg:hidden">
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <ng-select [(ngModel)]="selectedType" [clearable]="false"
                       class="ng-select cheqme-style mt-2 block rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
              @for (type of types; track type) {
                <ng-option [value]="parseInt(type)">{{ WorkflowTargetLabels[parseInt(type)] }}</ng-option>
              }
            </ng-select>
          </div>
          <!-- Tabs at small breakpoint and up -->
          <div class="hidden lg:block">
            <nav class="-mb-px flex space-x-8">
              <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
              @for (type of types; track type) {
                <a (click)="selectedType = parseInt(type)"
                   class="whitespace-nowrap border-b-2 border-transparent px-1 pb-2 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                   [ngClass]="{'!border-theme-primary-700 !text-theme-primary-700': parseInt(type) == selectedType}">
                  {{ WorkflowTargetLabels[parseInt(type)] }}
                </a>
              }
            </nav>
          </div>
        </div>
      </div>
      <layout-page-scrollable class="h-full">
        @switch (selectedType) {
          @case (WorkflowTarget.TransactionFinished) {
            <app-transaction-workflow></app-transaction-workflow>
          }
        }
      </layout-page-scrollable>
    </div>
  </div>
</layout-page-container>
