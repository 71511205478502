import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AutomationComponent} from './automation.component';
import {RouterLink, RouterLinkActive, RouterModule} from "@angular/router";
import {AutomationRoutes} from "./x-models/x-enums/automation-routes";
import {AutomationManagerComponent} from './automation-manager/automation-manager.component';
import {AuthGuard} from "../../_auth/auth.guard";
import {PermissionService} from "../../_auth/permission.service";
import {StockMarketComponent} from './_views/stock-market/stock-market.component';
import {CountdownComponent} from "ngx-countdown";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {WorkflowsComponent} from "./workflows/workflows.component";

@NgModule({
  declarations: [
    AutomationComponent,
    AutomationManagerComponent,
    StockMarketComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterLinkActive,
    RouterLink,
    RouterModule.forChild([
      {
        path: AutomationRoutes.AutomationManager, component: AutomationManagerComponent,
        canActivate: [AuthGuard], data: {roles: PermissionService.Permissions_AutomationManagerComponent().default},
        title: "Automations-Manager"
      },
      {
        path: AutomationRoutes.ViewStockMarket, component: StockMarketComponent,
        canActivate: [AuthGuard], data: {roles: PermissionService.Permissions_AutomationViewComponent().default},
        title: "Aktienmarkt"
      },
      {
        path: AutomationRoutes.Workflows, component: WorkflowsComponent,
        canActivate: [AuthGuard], data: {roles: PermissionService.Permissions_AutomationWorkflowsComponent().default},
        title: "Workflows"
      }
      //{
      //  path: '**', redirectTo: '/', pathMatch: 'full'
      //}
    ]),
    CountdownComponent,
    NgSelectModule,
    FormsModule
  ]
})
export class AutomationModule {
}
