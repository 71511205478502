import {Component, Input} from '@angular/core';
import {BookingType, BookingTypeLabels} from "../../../../../../models/payment/booking-type";
import {NgSelectModule} from "@ng-select/ng-select";
import {TransactionWorkflowFilter} from "../../../../_models/WorkflowTargets/transaction-workflow-filter";
import {queue} from "../../../../../../helpers/delay-helper";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-booking-type-selector',
  standalone: true,
  imports: [
    NgSelectModule,
    FormsModule
  ],
  templateUrl: './booking-type-selector.component.html',
  styleUrl: './booking-type-selector.component.scss'
})
export class BookingTypeSelectorComponent {

  protected _filter: TransactionWorkflowFilter | undefined;
  @Input() set filter(filter: TransactionWorkflowFilter | undefined) {
    this._filter = filter;
    if (this._filter !== undefined) this._filter.values = [];
  }

  private static values: number[] = [BookingType.Cash, BookingType.Card, BookingType.Employee, BookingType.BookingItem];
  protected get values() {
    return BookingTypeSelectorComponent.values;
  }

  protected readonly BookingTypeLabels = BookingTypeLabels;

  protected selected: number | null = null;

  protected onSelected(value: number | null) {
    if (value != undefined && this._filter != undefined) {
      this._filter.add(value.toString());
    }
    queue().then(() => this.selected = null);
  }

  protected getSortedModels(): number[] {
    if (this._filter === undefined) return [];
    return this._filter.values
      .map(v => parseInt(v.value))
      .sort((a, b) => a - b);
  }
}
