<app-navbar-container breadcrumb="Automation">
  <app-navbar-item-large
    *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_AutomationManagerComponent())"
    title="Automations-Manager"
    [link]="'/'+Routes.AutomationModule + '/' + AutomationRoutes.AutomationManager">
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-stopwatch" viewBox="0 0 16 16">
      <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"/>
      <path
        d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"/>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large
    *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_AutomationWorkflowsComponent())"
    title="Workflows"
    [link]="'/'+Routes.AutomationModule + '/' + AutomationRoutes.Workflows">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <path fill="currentColor"
            d="M176 160a39.9 39.9 0 0 0-28.62 12.09l-46.1-29.63a39.8 39.8 0 0 0 0-28.92l46.1-29.63a40 40 0 1 0-8.66-13.45l-46.1 29.63a40 40 0 1 0 0 55.82l46.1 29.63A40 40 0 1 0 176 160m0-128a24 24 0 1 1-24 24a24 24 0 0 1 24-24M64 152a24 24 0 1 1 24-24a24 24 0 0 1-24 24m112 72a24 24 0 1 1 24-24a24 24 0 0 1-24 24"/>
    </svg>
  </app-navbar-item-large>
</app-navbar-container>
