import {Component} from '@angular/core';
import {TransactionWorkflowSubComponent} from "../transaction-workflow-sub/transaction-workflow-sub.component";
import {TransactionWorkflowAction} from "../../../../_models/WorkflowTargets/transaction-workflow-action";
import {WorkflowActionTarget, WorkflowActionTargetLabels} from "../../../../_models/_enums/workflow-action-target";
import {
  TransactionWorkflowFilterTarget
} from "../../../../_models/WorkflowTargets/transaction-workflow-filter-target";
import {WorkflowFilterTypeLabels} from "../../../../_models/_enums/workflow-filter-type";
import {NgSelectModule} from "@ng-select/ng-select";
import {queue} from "../../../../../../helpers/delay-helper";
import {FormsModule} from "@angular/forms";
import {PrinterSelectorComponent} from "../printer-selector/printer-selector.component";
import {TelegramSelectorComponent} from "../telegram-selector/telegram-selector.component";

@Component({
  selector: 'app-transaction-workflow-action',
  standalone: true,
  imports: [
    NgSelectModule,
    FormsModule,
    PrinterSelectorComponent,
    TelegramSelectorComponent
  ],
  templateUrl: './transaction-workflow-action.component.html',
  styleUrl: './transaction-workflow-action.component.scss'
})
export class TransactionWorkflowActionComponent extends TransactionWorkflowSubComponent<TransactionWorkflowAction> {
  constructor() {
    super(WorkflowActionTarget);
  }

  protected get target() {
    return this.model.target;
  }

  protected set target(value: WorkflowActionTarget) {
    queue().then(() => this.model.target = value);
  }

  protected readonly WorkflowActionTargetLabels = WorkflowActionTargetLabels;
  protected readonly TransactionWorkflowFilterTargets = TransactionWorkflowFilterTarget;
  protected readonly WorkflowFilterTypeLabels = WorkflowFilterTypeLabels;
  protected readonly parseInt = parseInt;
  protected readonly TransactionWorkflowAction = TransactionWorkflowAction;
  protected readonly WorkflowActionTarget = WorkflowActionTarget;
}
