export enum HelpType {
  None,
  Cvd,
  Security,
  Technical,
  Change,
  Runner
}

export const HelpTypeLabel: { [key: string]: string } = {
  [HelpType.None]: '-',
  [HelpType.Cvd]: 'CvD',
  [HelpType.Security]: 'Security',
  [HelpType.Technical]: 'Technik',
  [HelpType.Change]: 'Wechselgeld',
  [HelpType.Runner]: 'Runner'
}
