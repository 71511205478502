export enum TransactionWorkflowFilterTarget {
  Composition = 1 << 0,
  CompositionType = 1 << 1,
  BookingType = 1 << 2
}

export const TransactionWorkflowFilterTargetsLabels: { [key: string]: string } = {
  [TransactionWorkflowFilterTarget.Composition]: 'Artikel',
  [TransactionWorkflowFilterTarget.CompositionType]: 'Artikel-Kategorie',
  [TransactionWorkflowFilterTarget.BookingType]: 'Zahlungsart'
}
