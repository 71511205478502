import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {TransactionWorkflow} from "../_models/WorkflowTargets/transaction-workflow";
import {ServerEndpoints} from "../../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class WorkflowHttpService {

  constructor(private http: HttpClient, @Inject("BASE_URL") private url: string) {
  }

  list(): Observable<TransactionWorkflow[]> {
    return this.http.get<TransactionWorkflow[]>(`${this.url}${ServerEndpoints.Automation_Workflow_List}`);
  }

  add(workflow: TransactionWorkflow): Observable<TransactionWorkflow> {
    console.log(workflow);
    return this.http.put<TransactionWorkflow>(`${this.url}${ServerEndpoints.Automation_Workflow_Add}`, workflow);
  }

  setValue(id: string, property: string, value: any) {
    return this.http.put(`${this.url}${ServerEndpoints.Automation_Workflow_SetValue}/${id}`, {
      name: property,
      value: value
    });
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${this.url}${ServerEndpoints.Automation_Workflow_Delete}/${id}`);
  }

  get(id: string): Observable<TransactionWorkflow> {
    return this.http.get<TransactionWorkflow>(`${this.url}${ServerEndpoints.Automation_Workflow_Get}/${id}`);
  }
}
